import React, { useRef } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { TestimonialSlider } from '../../components/index';
import "./Testmonial.scss";

function Testmonial() {
  const sliderRef = useRef();
  const testmonialData = [
    {
      id: 1,
      name: "Shanmugam K",
      review: "Best Chip level Training institute. I learned chip level service very quickly because they teach from fundamental to high-end laptop motherboards. After the course completion I can able to find the problem and solution with in 10mins. A wonderful, friendly Trainer. Now I am confident to start a service center. Thank you sir. All the best for upcoming learners.",
      rating: 5
    },
    {
      id: 2,
      name: "T Dhanaraj",
      review: "I roamed around all of Ritchie street and lucky to find this service centre. They are highly transparent, very economical, friendly and polite. They are technically qualified and also run an education centre there. Even service is carried out in right front of us. I highly recommend them and happy to have found a reliable service centre.",
      rating: 5
    },
    {
      id: 3,
      name: "Shahul Hameed",
      review: "Absolutely fabulous experience! Quick response time with honest and reliable feedback. Lifesaver during mid semester at college when your hard drive crashes with all your project files in it. Highly recommended, Siddiq is truly an exp.",
      rating: 5
    },
    {
      id: 4,
      name: "MANISH TOMAR",
      review: "I am highly satisfied with service. It is better than any company authorised service Center. I had very bad experience with Acer service Center Vadaplani  they could not resolve my issue even in one month. But these people resolve within half an hour. Highly recommend. Thank you.",
      rating: 5
    },
    {
      id: 5,
      name: "Riyaj Deen",
      review: "Greenchip is the best computer service centre I have been to. They did a great job with my MacBook Pro rebooted and backed up all my data before that and re installed everything as it was. Servicing a Mac is not easy and authorised service centre was so unhelpful. Thanks, Greenchip keep it.up.",
      rating: 5
    },
    {
      id: 6,
      name: "Shahul Hameed",
      review: "GreenChip is the best training center to learn chip-level courses. It offers good infrastructure and experienced faculty, providing the best learning experience.",
      rating: 5
    },
  ];

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  function previous() {
    sliderRef.current.slickPrev();
  }

  function next() {
    sliderRef.current.slickNext();
  }

  return (
    <section className="testimonial-container">
      <div className="testimonial-wrapper">
        {/* Heading */}
       <div className="heading-container">
        {/* Heading text*/}
        <div className="heading-text">
          <p className='title'>Testimonial</p>
          <p className='subtitle'>What Our Customer Say About us</p>
        </div>

        {/* Button */}

        <div className="button-container">
          <button title='Previous' onClick={previous}><KeyboardArrowLeftIcon /></button>
          <button title='Next' onClick={next}><KeyboardArrowRightIcon /></button>
        </div>
       </div>

        {/* Slider */}
        <Slider {...settings} ref={sliderRef}>
          {
            testmonialData.map((data) => (
              <TestimonialSlider key={data.id} data={data} />
            ))
          }
        </Slider>
      </div>
    </section>
  )
};

export default Testmonial;
