import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import DevicesIcon from "@mui/icons-material/Devices";
import SchoolIcon from "@mui/icons-material/School";
import StoreIcon from "@mui/icons-material/Store";
import CallIcon from "@mui/icons-material/Call";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import "./MobileNav.scss";
import { Link } from "react-router-dom";
import { Link as SectionLink } from "react-scroll";

const MobileNav = ({ toggleDrawer, activeNav }) => {
  const navList = [
    {
      title: "Home",
      path: "/",
      icon: <HomeIcon />,
    },
    {
      title: "Course",
      path: "/course",
      icon: <SchoolIcon />,
    },
    {
      title: "Services",
      path: "/services",
      icon: <DevicesIcon />,
    },
    {
      title: "Store",
      path: "/store",
      icon: <StoreIcon />,
    },
  ];
  return (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      className="mobile-nav"
    >
      <List>
        {navList.map((nav) => (
          <ListItem key={nav.title} disablePadding>
            <Link to={nav.path} className="flex w-full">
              <ListItemButton
                className={`${
                  activeNav === nav.path.replace("/", "") ? "active" : ""
                } gap-16`}
              >
                <ListItemIcon className="mobile-nav-icon">
                  {nav.icon}
                </ListItemIcon>
                <ListItemText className="mobile-nav-text" primary={nav.title} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {["Contact"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <SectionLink to="contact-page">
                <Button type="button" className="mobile-nav-button">
                  <CallIcon />
                  {/* <ListItemText primary={text} /> */}
                  Contact
                </Button>
              </SectionLink>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default MobileNav;
