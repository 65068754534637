import React, { useState } from 'react';
import "./ContactForm.scss";

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const mailtoLink = `mailto:siddique@greenchip.in?subject=${encodeURIComponent(
      'Contact Form Submission'
    )}&body=${encodeURIComponent(
      `Name: ${name}%0D%0AEmail: ${email}%0D%0AMessage: ${message}`
    )}`;

    window.location.href = mailtoLink;
  };

  return (
    <form action="mailto:siddique@greenchip.in" onSubmit={handleSubmit} className='flex flex-col gap-12 items-center justify-center w-full contact-form'>
      {/* <label htmlFor="name">Name:</label> */}
      <input
        type="text"
        id="name"
        value={name}
        placeholder='Name'
        onChange={(e) => setName(e.target.value)}
      />

      {/* <label htmlFor="email">Email:</label> */}
      <input
        type="email"
        id="email"
        value={email}
        placeholder='Email'
        onChange={(e) => setEmail(e.target.value)}
      />

      {/* <label htmlFor="message">Message:</label> */}
      <textarea
        id="message"
        value={message}
        placeholder="Enter your message here"
        onChange={(e) => setMessage(e.target.value)}
        rows={6}
      ></textarea>

      <button type="submit">Submit</button>
    </form>
  );
};

export default ContactForm;