import React from "react";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import "./TestimonialSlider.scss";

function TestimonialSlider({ data }) {
  const { name, review, rating } = data;
  const stars = [];

  for (let i = 1; i <= 5; i++) {
    if (i <= Math.floor(rating)) {
      stars.push(<StarIcon key={i} />);
    } else if (rating % 1 !== 0) {
      stars.push(<StarHalfIcon key={i} />);
    } else {
      stars.push(<StarOutlineIcon key={i} />);
    }
  }

  return (
    <div className="slider-container">
      <div className="slider-wrapper">
        <div className="quote">
          <FormatQuoteIcon />
        </div>
        <div className="review">{review}</div>
        <div className="name">- {name}</div>
        <div className="rating">{stars}</div>
      </div>
    </div>
  );
}

export default TestimonialSlider;
