import React from "react";
import BuildIcon from "@mui/icons-material/Build";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import LaptopIcon from "@mui/icons-material/Laptop";
import { ExpertiseCard } from "../index";
import "./Expertise.scss";

function Expertise() {
  const serviceCardsData = [
    {
      id: "1",
      title: "Professional Laptop Repair and Maintenance Services",
      description:
        "Our expert technicians provide reliable and affordable repair services for all laptop brands and models. From screen replacements to virus removals, we have you covered.",
      buttonText: "Learn More",
      icon: <BuildIcon />,
      iconColor: "#ff8345",
      iconBg: "#fff0e9",
      link: "course"
    },
    {
      id: "2",
      title: "Expert Chip-Level Laptop and Desktop Training",
      description:
        "Our comprehensive training program teaches you how to diagnose and repair laptops and desktops at the chip level. Gain the skills and knowledge needed to start a successful career in the IT industry.",
      buttonText: "Enroll Now",
      icon: <DeveloperBoardIcon />,
      iconColor: "#48b774",
      iconBg: "#edf8f4",
      link: "services"
    },
    {
      id: "3",
      title: "High-Quality Laptops at Affordable Prices",
      description:
        "We offer a wide range of new and refurbished laptops from top brands. All of our laptops are rigorously tested to ensure high quality and performance.",
      buttonText: "Shop Now",
      icon: <LaptopIcon />,
      iconColor: "#ffca45",
      iconBg: "#fff6dd",
      link: "store"
    },
  ];

  return (
    <section className="services-container">
      <div className="services-wrapper">
        <div className="heading">
          <p className="title">Our Expertise</p>
          <p className="subtitle">What We Do</p>
        </div>

        {/* Services card */}
        <div className="services-cards-container">
          {serviceCardsData.map((cardDetails) => (
            <ExpertiseCard key={cardDetails.id} cardDetails={cardDetails} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Expertise;
