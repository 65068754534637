import React from "react";
import "./LaptopCard.scss";

const LaptopCard = ({ data }) => {
  const {
    brand,
    model,
    src,
    processor,
    ram,
    storage,
    screen,
    keyboard,
    condition,
  } = data;
  return (
    <div className="laptop-card hover:scale-110 cursor-pointer transition-all duration-500 ease-in-out">
      <div className="laptop-card-wrapper">
        <div className="laptop-card__img__container">
          <img src={src} alt="laptop" />
        </div>
        <div className="content">
          <p className="content-text">
            <span>Model:&nbsp;</span>
            {brand} {model}
          </p>
          <p className="content-text">
            <span>Specification:&nbsp;</span>
            <ul>
              <li>{processor}</li>
              <li>{ram}</li>
              <li>{storage}</li>
              <li>{screen}</li>
              <li>{keyboard}</li>
              <li>{condition}</li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LaptopCard;
