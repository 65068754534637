import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Link as SectionLink } from "react-scroll";
import CallIcon from "@mui/icons-material/Call";
import { Button, Drawer, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import "./Header.scss";
import MobileNav from "../MobileNav/MobileNav";

function Header() {
  const location = useLocation();
  const [activeNav, setActiveNav] = useState("");
  const [navStatus, setNavStatus] = useState(false);
  const [isFixed, setIsFixed] = useState(false);

  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setNavStatus((prevNavStatus) => !prevNavStatus);
  };

  useEffect(() => {
    if (location) {
      const currentNav = location.pathname.replace("/", "");
      setActiveNav(currentNav);
    }
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const heroSectionHeight = 700;

      if (scrollPosition > heroSectionHeight) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return() => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <header className={`${isFixed ? "sticky inset-0 z-20 bg-active shadow-md shadow-green-50" : ""} sticky block transition-all duration-500 ease-in-out `}>
      <div className="header-container">
        {/* Logo */}
        <Link to="/" className="logo-container">
          <img
            src="./Images/logo.png"
            alt="logo"
            className="logo-image"
            title="Greenchip"
          />
          {/* <p className="logo-text">Greenchip</p> */}
        </Link>

        {/* Navlink */}
        <nav className="nav-container">
          <ul className="nav-wrapper">
            <li className={`${activeNav === "" ? "active" : ""}`}>
              <Link to="/">Home</Link>
            </li>
            <li className={`${activeNav === "course" ? "active" : ""}`}>
              <Link to="/course">Course</Link>
            </li>
            <li className={`${activeNav === "services" ? "active" : ""}`}>
              <Link to="/services">Services</Link>
            </li>
            <li className={`${activeNav === "store" ? "active" : ""}`}>
              <Link to="/store">Store</Link>
            </li>
          </ul>
        </nav>

        {/* Button */}
        <div className="button-container">
          <SectionLink
            to="contact-page"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
          >
            <Button type="button">
              <CallIcon />
              Contact
            </Button>
          </SectionLink>
        </div>

        {/* Mobile Navigation */}
        <div className="hamburger-menu-container">
          <IconButton onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <Drawer anchor="right" open={navStatus} onClose={toggleDrawer(false)}>
            {<MobileNav toggleDrawer={toggleDrawer} activeNav={activeNav} />}
          </Drawer>
        </div>
      </div>
    </header>
  );
}

export default Header;
