import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

// Pages
import RootLayout from "./layout/RootLayout";
import Home from "./pages/Home/Home"
import Course from "./pages/Course/Course";
import Services from "./pages/Services/Services";
import Store from "./pages/Store/Store";

import "./App.scss";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootLayout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/course",
          element: <Course />
        },
        {
          path: "/services",
          element: <Services />
        },
        {
          path: "/store",
          element: <Store />
        }
      ],
    },
  ]);

  return (
    <div className="app">
      <AnimatePresence mode="wait">
        <RouterProvider router={router} />
      </AnimatePresence>
    </div>
  );
}

export default App;
