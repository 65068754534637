import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import "./Footer.scss";

function Footer() {
  return (
    <footer className="footer-container">
      <div className="footer-wrapper">
        <p className="copyright-section">
          &#169; Copyright, Greenchip 2023, All rights reserved.
        </p>
        <span className="social-media-links">
          <a
            href="https://www.facebook.com/profile.php?id=100077439067449"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon />
          </a>
          <a
            href="https://www.instagram.com/siddique_greenchip/?igshid=OGRjNzg3M2Y%3D"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon />
          </a>
          <a href="https://wa.me/9962405734" target="_blank" rel="noreferrer">
            <WhatsAppIcon />
          </a>
        </span>
      </div>
    </footer>
  );
}

export default Footer;
