import React from "react";
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import "./Contact.scss";
import ContactForm from "../ContactForm/ContactForm";


function Contact() {
  return (
     <section className="contact-container" id="contact-page">
        <div className="contact-wrapper">
        {/* heading */}
        <div className="heading-container">
          <p className="title">Contact us</p>
          <p className="description">Get in touch with us.</p>
        </div>

        <div className="content-container">
          {/* Contact info */}
          <div className="contact-info-container">
            <div className="address-container">
              <LocationCityIcon />
              <p>
                NO.8 NAMBI TRADE CENTER, 2nd Floor, NARASHINGAPURAM STREET,
                Ritchie St, Mount Road, Chennai, Tamil Nadu 600002
              </p>
            </div>

            <div className="phone-container">
              <PhoneIcon />
              <p>99624 05734</p>
            </div>

            <div className="mail-container">
              <EmailIcon />
              <p>siddique@greenchip.in</p>
            </div>

            <div className="store-time">
              <AccessTimeFilledIcon />
              <div>
                <p>Mon-Sat : 10:00am - 8:00pm</p>
                <p>Sun: 10:00am - 1:00pm</p>
              </div>
            </div>
          </div>

          <div>
            <ContactForm />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact;