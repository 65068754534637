import React from "react";
import SchoolIcon from "@mui/icons-material/School";
import GradeIcon from "@mui/icons-material/Grade";
import ChatIcon from "@mui/icons-material/Chat";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import VerifiedIcon from "@mui/icons-material/Verified";
import { WhyUsCard } from "../../components";
import "./WhyUs.scss";

function WhyUs() {
  const whyUsData = [
    {
      id: 1,
      title: "Expertise",
      description:
        "Our experienced technicians are trained to handle a wide range of laptop and desktop issues, from basic troubleshooting to complex chip-level repairs.",
      icon: <SchoolIcon />,
    },
    {
      id: 2,
      title: "Quality",
      description:
        "We use high-quality parts and tools to ensure that your device is repaired to the best possible standard. We stand behind our work with a warranty on all repairs.",
      icon: <GradeIcon />,
    },
    {
      id: 3,
      title: "Customer service",
      description:
        "Our friendly staff will keep you updated on the status of your repair, answer any questions you may have, and ensure that you have a positive experience with us.",
      icon: <ChatIcon />,
    },
    {
      id: 4,
      title: "Convenience",
      description:
        "We offer a range of services, including training, repairs, and sales, all under one roof. We strive to make the process as convenient and efficient as possible for our customers.",
      icon: <CalendarMonthIcon />,
    },
    {
      id: 5,
      title: "Affordability",
      description:
        "We offer competitive pricing for all of our services and strive to provide value for our customers' money.",
      icon: <AttachMoneyIcon />,
    },
    {
      id: 6,
      title: "Trust",
      description:
        "We have built a reputation for honesty, transparency, and integrity in our dealings with our customers. You can trust us to provide reliable, professional service every time.",
      icon: <VerifiedIcon />,
    },
  ];

  return (
    <section className="why-us-container">
      <div className="why-us-wrapper">
        <div className="heading-container">
          <p className="title">Why us</p>
          <p className="subtitle">
            Learn Why Green Chip is the Best in the Business
          </p>
        </div>

        {/* why us cards */}
        <ul className="features-container">
          {whyUsData.map((data) => (
            <WhyUsCard key={data.id} data={data} />
          ))}
        </ul>
      </div>
    </section>
  );
}

export default WhyUs;