import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import "./Store.scss";
import LaptopCard from "../../components/LaptopCard/LaptopCard";
import { Button } from "@mui/material";

function Store() {
  const laptopData = [
    {
      brand: "DELL",
      model: "5480",
      src: "/Images/dell-5480.png",
      processor: "I5 6th generation",
      ram: "8 GB",
      storage: "256GB SSD",
      screen: "14 SCREEN",
      keyboard: "Non-backlit keyboard",
      condition: "A+ Condition",
    },
    {
      brand: "DELL",
      model: "e5470",
      src: "/Images/dell-5470.png",
      processor: "I5 6th generation",
      ram: "8 GB",
      storage: "256GB SSD",
      screen: "14 FHD SCREEN",
      keyboard: "Backlit keyboard",
      condition: "A+ Condition",
    },
    {
      brand: "DELL",
      model: "5470",
      src: "/Images/dell-5470.png",
      processor: "I5 6th generation",
      ram: "8 GB",
      storage: "256GB SSD",
      screen: "14 FHD TOUCH SCREEN",
      keyboard: "Backlit keyboard",
      condition: "A+ Condition",
    },
    {
      brand: "DELL",
      model: "7490",
      src: "/Images/dell-7490.png",
      processor: "I5 8th generation",
      ram: "8 GB",
      storage: "256GB SSD",
      screen: "14 FHD SCREEN",
      keyboard: "Backlit keyboard",
      condition: "A+ Condition",
    },
    {
      brand: "HP",
      model: "840-G5",
      src: "/Images/hp-840g5.png",
      processor: "I5 7th generation",
      ram: "8 GB",
      storage: "256GB SSD",
      screen: "14 FHD TOUCH SCREEN",
      keyboard: "Backlit keyboard",
      condition: "A+ Condition",
    },
    {
      brand: "HP",
      model: "840-G5",
      src: "/Images/hp-840g5.png",
      processor: "I5 8th generation",
      ram: "8 GB",
      storage: "256GB SSD",
      screen: "14 FHD TOUCH SCREEN",
      keyboard: "Backlit keyboard",
      condition: "A+ Condition",
    },
  ];
  return (
    <div className="store">
      <section className="store-hero">
        <div className="store-hero__wrapper grid md:grid-cols-2 grid-cols-1 text-center md:text-left gap-16 items-center justify-center relative">
          <div className="store__content__container">
            <p className="title">
              <span>One Stop Destination</span> for Multi-Brand Laptops
            </p>
            <p className="subtitle">
              Find the Perfect Laptop to Enhance Your Productivity. Contact Us
              for Expert Guidance and Personalized Recommendations
            </p>
            <a href="https://wa.me/9962405734" target="_blank" rel="noreferrer" className="flex items-center justify-center md:justify-start">
              <Button type="button">
                <WhatsAppIcon />
                Whatsapp Us
              </Button>
            </a>
          </div>

          <img src="/Images/store-hero-image.png" alt="laptop" className="store__img justify-self-center" />
        </div>
      </section>

      <section className="store-content">
        <div className="store-content__wrapper">
          <div className="content-title">
            <h2>Popular Products</h2>
          </div>

          <div className="content-listing grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {laptopData.map((data) => (
              <LaptopCard data={data} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Store;
