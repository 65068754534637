import React from 'react';
import "./RoadMapCard.scss";

const RoadMapCard = ({ data }) => {
  return (
    <div className="roadmap">
      <h3>{data.title}</h3>
      <p>{data.topics}</p>
    </div>
  )
}

export default RoadMapCard