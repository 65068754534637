import React from "react";
import "./ServiceCard.scss";

const ServiceCard = ({ data, index }) => {
  return (
    <div className="service grid grid-cols-1 md:grid-cols-2 gap-16 items-center justify-center text-center md:text-left">
      <div className={`service-image-container ${(index + 1) % 2 === 0 ? 'md:col-end-3 md:row-start-1' : ''} flex justify-center items-center`}>
        <img src={data.imgSrc} alt="laptop service" />
      </div>
      <div className={`service-content-container ${(index + 1) % 2 === 0 ? 'md:col-start-1 md:row-start-1' : ''}`}>
        <h3 className="service-content-title">{data.title}</h3>
        <p className="service-content-description">{data.description}</p>
      </div>
    </div>
  );
};

export default ServiceCard;
