import React from "react";
import "./WhyUsCard.scss";

function WhyUsCard({ data }) {
  const { title, description, icon } = data;

  return (
    <li className="feature-container">
      <div className="icon-container">{icon}</div>
      <div className="feature-content">
        <p className="title">{title}</p>
        <p className="description">{description}</p>
      </div>
    </li>
  );
}

export default WhyUsCard;
