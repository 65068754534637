import React from "react";
import "./Course.scss";
import { Button } from "@mui/material";
import { InstructorInfo, RoadMapCard } from "../../components";

const Course = () => {
  const syballusData = [
    {
      title: "Section 1: Basic Electronics (8 hours)",
      topics:
        "The architecture of Motherboard (Laptop and Desktop), Current and Voltage, Types of Circuits, Electronics Components List (Resistor, Capacitor, Diode, Transistor, MOSFET, Inductor, Opto-Coupler, Transformer, Bridge Rectification), Revision of All above Topics, S.M.P.S Repairing (Practical)",
    },
    {
      title: "Section 2: Desktop Motherboard Chip Level (16 hours)",
      topics:
        "Motherboard Observation (P4, Dual Core, c2d, I3, I5, I7), S.M.P.S Pin Details, CMOS Battery, Bios (EEPROM), Clock Generator, LAN I.C, Audio, Connectors (USB, LAN, AUDIO, VGA, AGP, SERIAL, PARALLEL, KEYBOARD, MOUSE, SATA, IDE, RAM), Power section VRM (voltage regulated module), South & North Bridge, RAM, and embedded controller",
    },
    {
      title: "Section 3: Practical (16 hours)",
      topics:
        "BIOS reprogramming with BIOS kit, Soldering practice (All normal & SMD components included all types of leg IC'S), Motherboard Reflo, B.G.A machine operating, Reballing B.G.A I.C'S, Live tracing of motherboard, Troubleshooting a faulty motherboard, Symptoms and solutions",
    },
    {
      title: "Section 4: Laptop Motherboard Chip Level (16 hours)",
      topics:
        "Motherboard Observation (P4, Dual Core, c2d, I3, I5, I7), Laptop power sequence, Adapter internal circuit, Battery internal circuit, LCD/LED internal circuit and inverter, List of power sections & their output section, RAM Voltages & signals, Display voltages & signals, Clock generator I.C & their output frequencies, USB, LAN section, Keyboard & Touchpad, HDD voltages & signals, Processor, N.B, S.B & Graphics IC'S fault finding",
    },
    {
      title: "Section 5: Practical (4 hours)",
      topics:
        "Soldering practice (All normal & SMD components included all types of leg I.C'S), Motherboard Reflo, B.G.A machine operating, Reballing B.G.A I.C'S, Live tracing of motherboard, Troubleshooting a faulty motherboard, Symptom and solution",
    },
  ];

  const instructorData = {
    name: "SIDDIQUE HUSSAIN M",
    role: "Instructor",
    description:
      "Siddique Hussain M is a highly skilled professional with a Diploma in Electrical & Electronics Communication Engineering. With a strong technical background and 15 years of experience, he has worked as a Service Engineer, Trainer, and Managing Director. His expertise and industry knowledge make him an exceptional instructor in the field.",
  };
  return (
    <div className="course">
      <section className="course-hero">
        <div className="course-hero__wrapper grid gap-16 grid-cols-1 md:grid-cols-2 items-center justify-center">
          <div className="course-hero__content text-center md:text-left">
            <h2 className="course-hero__title">
              <span>Master Chip-Level&nbsp;</span>Laptop and Desktop Engineering
            </h2>
            <p className="course-hero__subtitle">
              Gain hands on experience, master advanced techniques, and become a
              certified technician ready to tackle any challenge in laptop and
              desktop repairs. Unlock your potential today!
            </p>
            <a
              href="/syballus.pdf"
              download
              className="flex items-center justify-center md:justify-start"
            >
              <Button type="button">Download Syballus</Button>
            </a>
          </div>

          <div className="course-hero__img justify-self-center w-11/12">
            <img src="/Images/training.svg" alt="Coaching" />
          </div>
        </div>
      </section>

      {/* Course Info */}
      <section className="course-content">
        <div className="course-content__wrapper">
          {/* Instructor Info */}
          <div className="course-content-instructor flex flex-col justify-center items-center">
            <h2 className="course-content-instructor__title">
              Instructor Info
            </h2>
            <InstructorInfo data={instructorData} />
          </div>

          {/* Roadmap */}
          <div className="course-content-roadmap flex flex-col justify-center items-center">
            <h2 className="course-content-roadmap__title">
              Course Roadmap&nbsp;<span>(Duration: 60-Hours)</span>
            </h2>

            <div className="course-content-roadmap__wrapper">
              {syballusData.map((data, index) => (
                <div className="course-content-roadmap__block">
                  <RoadMapCard data={data} />
                  {syballusData.length - 1 === index ? (
                    ""
                  ) : (
                    <div className="vertical-line" />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Course;
