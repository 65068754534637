import React from "react";
import { Button } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import "./Services.scss";
import { ServiceCard } from "../../components";

const Services = () => {
  const servicesList = [
    {
      title: "Expert Laptop/PC Services",
      description: " Our skilled technicians provide top-notch laptop/PC services, including hardware and software repairs, upgrades, and optimizations. Trust us to keep your devices running smoothly and efficiently, ensuring enhanced productivity and a seamless computing experience.",
      imgSrc: "/Images/laptop-services.svg"
    },
    {
      title: "Reliable Data Recovery Services",
      description: "Accidentally lost important files? ur data recovery experts are here to help! With advanced techniques and state-of-the-art tools, we specialize in retrieving lost data from various storage devices, ensuring the highest level of confidentiality and successful data restoration.",
      imgSrc: "/Images/data-recovery.svg"
    },
    {
      title: "Mac/Apple Service Specialists",
      description: "Experience exceptional service for your Mac/Apple devices with our team of certified technicians. From hardware repairs to software troubleshooting, we provide comprehensive solutions to ensure your Mac/Apple devices are in optimal condition, allowing you to maximize their performance and efficiency.",
      imgSrc: "/Images/mac.png"
    },
    {
      title: "Efficient Troubleshooting Services",
      description: "Encounter tech issues? Leave it to our skilled professionals to diagnose and resolve them swiftly. With our expertise in troubleshooting various hardware and software problems, we aim to provide quick and effective solutions, minimizing downtime and ensuring your devices are back up and running smoothly.",
      imgSrc: "/Images/troubleshoot.svg"
    }
  ];
  return (
    <div className="services">
      <section className="services-hero text-center md:text-left">
        <div className="services-hero__wrapper grid grid-cols-1 md:grid-cols-2 items-center justify-center gap-16">
          <div className="services-hero__content">
            <h2 className="services-hero__title">
              <span>Experience reliable</span> laptop and desktop services
            </h2>
            <p className="services-hero__subtitle">
              Our expert technicians provide efficient solutions for hardware,
              software, and data-related issues. Get your devices back on track
              and enjoy seamless operation. Contact us for reliable solutions
              and peace of mind.
            </p>

            <a href="https://wa.me/9962405734" target="_blank" rel="noreferrer" className="flex items-center justify-center md:justify-start">
              <Button type="button">
                <WhatsAppIcon />
                Whatsapp Us
              </Button>
            </a>
          </div>

          <div className="services-hero__img justify-self-center w-10/12">
            <img src="/Images/services.svg" alt="services" />
          </div>
        </div>
      </section>
      
      <section className="services-content">
        <div className="services-content__wrapper flex flex-col items-center justify-center">
          <h2 className="services-content__title flex items-center justify-center">Our Services</h2>
          {/* ServicesCard */}
          {
            servicesList.map((service, index) => (
              <div>
                <ServiceCard data={service} index={index} />
              </div>
            ))
          }
        </div>
      </section>
    </div>
  );
};

export default Services;
