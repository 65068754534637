import React from "react";
import { Hero, Expertise, WhyUs, Testimonial, Contact } from "../../components/index";

function HomeLayout() {
  return (
    <>
      <Hero />
      <Expertise />
      <WhyUs />
      <Testimonial />
      <Contact />
    </>
  );
}

export default HomeLayout;
