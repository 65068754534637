import React from 'react';
import { Outlet } from "react-router-dom";
import { Header, Footer } from "../components/index";

function RootLayout() {
  return (
    <>
      <Header />

      <main className="main-container">
        <Outlet />
      </main>
      
      <Footer />
    </>
  )
}

export default RootLayout;