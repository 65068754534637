import React from "react";
import "./InstructorInfo.scss";

const InstructorInfo = ({ data }) => {
  return (
    <div className="instructor-info grid grid-cols-1 md:grid-cols-3 items-center justify-center gap-12">
      <div className="instructor-info-img-container col-span-1 flex flex-col items-center justify-center">
        <img src="/Images/profile2.png" alt="profile" />
        <p className="instructor-info-img-container__name">{data.name}</p>
        <p className="instructor-info-img-container__role">{data.role}</p>
      </div>

      <div className="instructor-info-content col-span-1 md:col-span-2">
        <p className="instructor-info-content__description">
          {data.description}
        </p>
      </div>
    </div>
  );
};

export default InstructorInfo;
