import React from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { motion } from 'framer-motion';
import "./ExpertiseCard.scss";
import { Link } from 'react-router-dom';

function ExpertiseCard({ cardDetails }) {
  const { title, description, icon, iconColor, iconBg, buttonText, link } = cardDetails;
  return (
    <motion.div
      whileHover={{ scale: 1.1 }}
      className="service-card-container"
    >
      <div className="icon-container"  style={{ backgroundColor: iconBg, color: iconColor }}>
        {icon}
      </div>

      <div className="card-text">
        <p className="title">{title}</p>
        <p className="description">{description}</p>
      </div>

      <Link to={link} className="button-container">
        <button>
          {buttonText}
          <ArrowRightAltIcon />
        </button>
      </Link>
    </motion.div>
  )
}

export default ExpertiseCard;