import React from "react";
import "./Hero.scss";
import BuildIcon from "@mui/icons-material/Build";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Link } from "react-router-dom";

function Hero() {
  return (
    <section className="hero-container">
      <div className="hero-wrapper">
        {/* Left */}
        <div className="hero-content">
          <h1 className="hero-heading">
            Welcome to <span>Green Chip Institute of Advanced Technology</span>
          </h1>
          <p className="hero-subheading">
            Experts in Providing Comprehensive Laptop and Desktop Chip-level
            Training, Professional Repairing Services for All Brands and Models,
            and Affordable Sales of High-quality Laptops.
          </p>

          <div className="button-container">
            <Link to="/course">
              <button className="--modifier-enroll">
                <PersonAddIcon />
                Enroll Now
              </button>
            </Link>

            <Link to="/services">
              <button className="--modifier-repair">
                <BuildIcon />
                Repair Now
              </button>
            </Link>
          </div>
        </div>

        {/* Right */}

        <div className="hero-image">
          <img src="./Images/hero-image.svg" alt="A man servicing a laptop" />
        </div>
      </div>
    </section>
  );
}

export default Hero;
